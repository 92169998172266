.Settings {
  color: var(--color-disabled);
  display: flex;
  flex-direction: column;
  /*font-family: Verdana, Geneva, sans-serif;*/
  font-size: 1.25rem;
  position: fixed;
  right: 2rem;
  top: 1rem;
  z-index: 1;
}

.Settings-zoom {
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: 1.75rem;
  margin-top: 0.5rem;
}

.Settings-button {
  background: none;
  border: none;
  color: white;
  min-height: 2.5rem;
  min-width: 2.5rem;
  outline: none;
  transition: color var(--transition-duration) var(--transition-timing-function);
}

.Settings-zoom > .Settings-button {
  font-weight: 500;
}

/**
 * Variants
 */

.Settings-button--lang {
  text-transform: uppercase;
}

/**
 * States
 */

.Settings-button.is-disabled {
  color: var(--color-disabled);
}

.Settings-button:not(.is-disabled):hover,
.Settings-button:not(.is-disabled):active {
  color: var(--color-hover);
  cursor: pointer;
}
