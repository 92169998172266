/**
 * FootnoteGroup
 *
 * Absolute positioning for the footnotes.
 */

.FootnoteGroup {
  bottom: 10em;
  position: absolute;
  right: 25em;
  width: 60em;
}
