/**
 * TextBubble SVG
 *
 * 1. SVG should not mask other TextBubbles' click areas.
 */

.TextBubble {
  pointer-events: none;
}

.TextBubble-clickable {
  pointer-events: visibleFill; /* 1 */
}

.TextBubble-shape {
  opacity: 1;
  fill: var(--color-bubble-background);
  transition: opacity var(--transition-duration) var(--transition-timing-function);
}

.TextBubble-image {
  transition: opacity var(--transition-duration) var(--transition-timing-function);
}

.TextBubble.is-hidden .TextBubble-shape {
  cursor: pointer;
  opacity: 0;
}

.TextBubble.is-hidden .TextBubble-image {
  opacity: 0;
}

/**
 * Variant
 * How to do this properly?
 */

.DragScroll.is-dragging .TextBubble-shape {
  cursor: grabbing;
}
