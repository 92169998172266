.ExternalLink {
  cursor: pointer;
}

.ExternalLink-circle {
  fill: white;
  fill-opacity: 0;
  stroke: white;
  stroke-opacity: 0;
  transition: stroke-opacity var(--transition-duration) var(--transition-timing-function);
}

.ExternalLink:hover > .ExternalLink-circle,
.ExternalLink:active > .ExternalLink-circle {
  /*stroke-opacity: 0.5;*/
}
