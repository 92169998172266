/**
 * Footnote
 */

.Footnote {
  font-size: 1em;
  margin-bottom: 1em;
}

.Footnote-link {
  color: gray;
  transition: color var(--transition-duration) var(--transition-timing-function);
  text-decoration: none;
}

/**
 * States
 */

.Footnote.is-active .Footnote-link {
   color: white;
}

.Footnote-link:hover,
.Footnote-link:active {
  color: var(--color-hover);
}
