/**
 * Loading
 * Loading screen.
 *
 * 1. Center the loading spinner.
 */

.Loading {
  background-color: black;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Loading-inner {
  position: absolute; /* 1 */
  left: 50%; /* 1 */
  top: 50%; /* 1 */
  transform: translate(-48px, -48px); /* 1 */
}
