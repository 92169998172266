:root {
  --color-hover: rgba(255, 255, 255, 0.6);
  --color-disabled: rgba(255, 255, 255, 0.6);
  --color-bubble-background: rgb(48, 48, 48);
  --transition-duration: 250ms;
  --transition-timing-function: ease-in-out;
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: normal;
  src: url("/fonts/source-sans-pro_regular.woff2"),
    url("/fonts/source-sans-pro_regular.woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 500;
  src: url("/fonts/source-sans-pro_semi-bold.woff2"),
    url("/fonts/source-sans-pro_semi-bold.woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: bold;
  src: url("/fonts/source-sans-pro_bold.woff2"),
    url("/fonts/source-sans-pro_bold.woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-style: italic;
  src: url("/fonts/source-sans-pro_italic.woff2"),
    url("/fonts/source-sans-pro_italic.woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
}
