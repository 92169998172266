.Title {
  color: white;
  font-size: inherit;
  left: 48em;
  position: absolute;
  top: 48em;
  width: 120em;
}

.Title-title {
  font-size: 12.5em;
  font-weight: bold;
  line-height: 1.25;
  margin: 0;
}

/**
 * 1. Equivalent to 100px.
 */

.Title-subtitle {
  font-size: 3.125em;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 2em; /* 1 */
  margin-top: 0;
}

.Title-instructions {
  color: #c9c9c9;
  font: italic 1.5625em/1.4 "Source Sans Pro", sans-serif;
  margin-bottom: 0;
  margin-top: 0;
}
