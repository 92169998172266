.Link {
  cursor: pointer;
}

.Link-circle {
  fill: white;
  fill-opacity: 0;
  stroke: white;
  stroke-opacity: 0;
  transition: stroke-opacity var(--transition-duration) var(--transition-timing-function);
}
